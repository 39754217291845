import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";

import Logo1 from "../../scafolding/assets/images/govLogo.png";
import Stamp from "../../scafolding/assets/images/stamp.png";
import Photo from "../../scafolding/assets/images/photo.png";
import Signature from "../../scafolding/assets/images/signature.png";
import "./IDCard.css";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import AddressService from "../../security/api/services/AddressService";
import OrganizationService from "../../security/api/services/OrganizationService";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import {
  DISABILITY_BY_NATURE_NEPALI,
  DISABILITY_BY_SEVERITY_NEPALI,
  GENDER_NEPALI,
  ID_CATEGORY_NEPALI,
} from "../../utilities/constants/ITMISConstansts";
function SeniorCitizenIDCard(props) {
  const idCardDetails = props.location.state.data;
  const { t } = useTranslation();
  const [fullAddressEng, setFullAddressEng] = useState();
  const [fullAddressNep, setFullAddressNep] = useState();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [fullAddressEngOrg, setFullAddressEngOrg] = useState();
  const [fullAddressNepOrg, setFullAddressNepOrg] = useState();
  const [municipalityEng, setMunicipalityEng] = useState();
  const [municipalityNep, setMunicipalityNep] = useState();

  const [printModal, setPrintModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const footer = (
    <div>
      <Button label={t("ho")} icon="pi pi-check" onClick={async () => {
        await setIsCopy(true);
        await printFunction();
      }} />
      <Button label={t("haina")} icon="pi pi-times" onClick={async () => {
        await setIsCopy(false);
        await printFunction();
      }} />
    </div>
  );

  const printFunction = () => {
    print(document.getElementById("nepali"), document.getElementById('english'));
  }

  const onHide = () => {
    setPrintModal(false);
  }
  const convertDOBNepToNepali = (data) => {
    const dobNepArray = data.split("-");
    const dobNep =
      englishToNepaliNumber(dobNepArray[0]) +
      "-" +
      englishToNepaliNumber(dobNepArray[1]) +
      "-" +
      englishToNepaliNumber(dobNepArray[2]);
    return dobNep;
  };
  const convertIdToNepali = (data) => {
    const idArray = data.split("_");
    let idNepali = "";

    if(idArray.length > 2) {
      idNepali =
        englishToNepaliNumber(idArray[0]) +
        "_" +
        englishToNepaliNumber(idArray[1]) +
        "_" +
        englishToNepaliNumber(idArray[2]) +
        "_" +
        ID_CATEGORY_NEPALI[idArray[3]] +
        "_" +
        englishToNepaliNumber(idArray[4]);
    }  else if(idArray.length = 2) {
      idNepali =
        englishToNepaliNumber(idArray[0]) +
        "_" +
        englishToNepaliNumber(idArray[1]);
    }
    return idNepali;
  };
  useEffect(() => {
    console.log("props.location.state.data", props.location.state.data);
    idCardDetails.idCardNepaliNo = convertIdToNepali(idCardDetails.id);
    AddressService.getFullAddressEng(
      props.location.state.data.seniorCitizenAddressDetails.province,
      props.location.state.data.seniorCitizenAddressDetails.district,
      props.location.state.data.seniorCitizenAddressDetails.municipality,
      props.location.state.data.seniorCitizenAddressDetails.wardNo
    ).then((response) => {
      let res = response.data.data;
      setFullAddressEng(res.replace(",sl", ", "));
    });
    AddressService.getFullAddressNep(
      props.location.state.data.seniorCitizenAddressDetails.province,
      props.location.state.data.seniorCitizenAddressDetails.district,
      props.location.state.data.seniorCitizenAddressDetails.municipality,
      props.location.state.data.seniorCitizenAddressDetails.wardNo
    ).then((response) => {
      let resN = response.data.data;
      setFullAddressNep(resN.replace(",sl", ", "));
    });
    // OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
    OrganizationService.getOrganizationById(props.location.state.data.organization).then((response) => {
      console.log(response.data.data);
      setOrganizationDetails(response.data.data);
      let wardVal = response.data.data.organizationAddress.ward ? response.data.data.organizationAddress.ward : 0;
      AddressService.getFullAddressEng(
        response.data.data.organizationAddress.province,
        response.data.data.organizationAddress.district,
        response.data.data.organizationAddress.municipality,
        wardVal
      ).then((response) => {
        console.log("english address>>", response.data.data);
        let engAdd = response.data.data;
        if (engAdd) {
          let eAdd = engAdd.split(",sl");
          console.log(eAdd);
          setMunicipalityEng(eAdd[0]);
          setFullAddressEngOrg(eAdd[1]);
        }
      });
      AddressService.getFullAddressNep(
        response.data.data.organizationAddress.province,
        response.data.data.organizationAddress.district,
        response.data.data.organizationAddress.municipality,
        wardVal
      ).then((response) => {
        console.log("nepali address>>", response.data.data);
        let nepAdd = response.data.data;
        if (nepAdd) {
          let nAdd = nepAdd.split(",sl");
          setMunicipalityNep(nAdd[0]);
          setFullAddressNepOrg(nAdd[1]);
        }
      });
    });
  }, [props]);

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Card
        style={{ display: "flex", justifyContent: "center", margin: "16px", borderRadius: "8px" }}
      >
        <div className="section-to-print">
          <div className="page" id="nepali">
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Image style={{ paddingRight: "7px" }} width={50} height={50} src={Logo1} />
                  </div>
                </div>
                <div style={{ width: "250px" }}>
                  <center>
                    <h4 style={{ color: "#d00000", paddingTop: "5px", fontSize: 11 }}>
                      {municipalityNep} <br />
                      {fullAddressNepOrg}
                    </h4>
                    <div
                      style={{
                        flex: 1,
                        marginTop: "5px",
                        background: "#d00000",
                        color: "#FFF",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        fontSize: 13,
                        width: "150px"
                      }}
                    >
                      ज्येष्ठ नागरिक परिचयपत्र
                    </div>
                  </center>
                </div>
                <div style={{ flex: 1 }}>
                  <Image
                    style={{ paddingLeft: "15px", paddingTop: "10px" }}
                    width={65}
                    height={72}
                    src={
                      "data:image/jpg;base64," +
                      idCardDetails.seniorCitizenIdCardPhotoInformation.fileData
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{ color: "black", width: "100%", float: "left", fontWeight: "bold", fontSize: 10, paddingLeft: 2 }}>
                  <div style={{ float: "left", width: "85%" }}>परिचयपत्र नं. :
                    {" "}{idCardDetails.idCardNo ? englishToNepaliNumber(idCardDetails.idCardNo) : idCardDetails.idCardNepaliNo}
                  </div>
                  {isCopy ?
                    <div style={{ width: "15%", float: "right" }}>
                      <strong>प्रतिलिपि</strong>
                    </div>
                    :
                    <></>
                  }
                  <div style={{ float: "left", width: "70%" }}>नाम, थर : {idCardDetails.firstNameNep +
                    " " +
                    (idCardDetails.middleNameNep ? idCardDetails.middleNameNep : "") +
                    " " +
                    idCardDetails.lastNameNep}
                  </div>
                  <div style={{ width: "30%", float: "right" }}>
                    लिङ्ग : {GENDER_NEPALI[idCardDetails.gender]}
                  </div>
                </div>
              </div>
              <div style={{ color: "black", width: "100%", float: "left", fontWeight: "bold", fontSize: 10, paddingLeft: 2 }}>
                <div style={{ width: "70%", float: "left" }}>
                  नागरिकता प्रमाणपत्र नं : {englishToNepaliNumber(idCardDetails.citizenshipNo)}{" "}
                </div>
                <div style={{ width: "30%", float: "right" }}>
                  रक्त समूह : {idCardDetails.bloodGroup}{" "}
                </div>
                {idCardDetails.dobNep ?
                  <div style={{ width: "100%", float: "left" }}>
                    {/* रोग: {idCardDetails.disease === "Yes" ? "छ" : "छैन"} */}
                    जन्म मिति : {convertDOBNepToNepali(idCardDetails.dobNep)}
                  </div>
                  :
                  <div style={{ width: "100%", float: "left" }}>
                    उमेर : {idCardDetails.age != null ? englishToNepaliNumber(idCardDetails.age) : ""}
                  </div>
                }
                ठेगाना :&nbsp;{englishToNepaliNumber(fullAddressNep)} <br />
                पति/पत्नीको नाम :&nbsp;
                {(idCardDetails.husbandWifefNameNep ? idCardDetails.husbandWifefNameNep : "")+
                  " " +
                  (idCardDetails.husbandWifemNameNep ? idCardDetails.husbandWifemNameNep : "") +
                  " " +
                  (idCardDetails.husbandWifelNameNep ? idCardDetails.husbandWifelNameNep : "")}
                <br />
                हेरचाह केन्द्रको नाम : {idCardDetails.careTakerSenCitHomeNep} <br />
                <div style={{ width: "70%", float: "left" }}>
                  सम्पर्क व्यक्ति : {idCardDetails.contactPersonfNameNep +
                    " " +
                    (idCardDetails.contactPersonmNameNep ? idCardDetails.contactPersonmNameNep : "") +
                    " " +
                    idCardDetails.contactPersonlNameNep}
                </div>
                <div style={{ width: "30%", float: "right" }}>
                  फोन नं : {idCardDetails.contactPersonNo != null ? englishToNepaliNumber(idCardDetails.contactPersonNo) : ""}
                </div>
              </div>
              <div style={{ color: "black", width: "100%", fontWeight: "bold", fontSize: 9, paddingLeft: 2 }}>
                <div style={{ width: "50%", float: "left" }}>
                  {/* <strong style={{ fontSize: 11 }}>अक्षमता को प्रकार</strong> <br />
                  प्रकृतिको आधारमा :{" "}
                  {DISABILITY_BY_NATURE_NEPALI[idCardDetails.typeOfDisabilityByNature]} <br />
                  गम्भीरताको आधारमा :{" "}
                  {DISABILITY_BY_SEVERITY_NEPALI[idCardDetails.typeOfDisabilityBySeverity]} <br /> */}

                  {idCardDetails.holderSignature != null ?
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "10px",
                        paddingTop: "20px",
                        paddingBottom: "25px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Image
                        style={{}}
                        width={90}
                        height={20}
                        src={
                          "data:image/jpg;base64," +
                          idCardDetails.holderSignature
                        }
                      />
                    </div>
                    :
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "10px",
                        paddingTop: "20px",
                        paddingBottom: "25px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                    </div>
                  }

                  परिचयपत्र बाहकको हस्ताक्षर
                </div>
                <div
                  style={{
                    width: "50%",
                    float: "right",
                    textAlign: "right",
                    paddingRight: 10,
                  }}
                >
                  <strong style={{ fontSize: 11 }}>स्वीकृत गर्ने </strong> <br />
                  {idCardDetails.seniorCitizenApprovedDetails.approvalSignature != null ?
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "99px",
                        paddingTop: "2px",
                        paddingBottom: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <Image
                        style={{}}
                        width={90}
                        height={20}
                        src={
                          "data:image/jpg;base64," +
                          idCardDetails.seniorCitizenApprovedDetails.approvalSignature
                        }
                      />
                    </div>
                    :
                    <div
                      style={{
                        flex: 1,
                        paddingLeft: "99px",
                        paddingTop: "2px",
                        paddingBottom: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                    </div>
                  }
                  हस्ताक्षर <br />
                  {idCardDetails.seniorCitizenApprovedDetails.nameNep} <br />
                  {idCardDetails.seniorCitizenApprovedDetails.designationNep}
                </div>
              </div>
              <div style={{ color: "black", paddingTop: "0px", fontWeight: "bold", fontSize: 7, textAlign: "center" }}>
                यो परिचयपत्र कसैले पाएमा नजिकको प्रहरी कार्यालय वा स्थानीय तहमा बुझाइदिनुहोला |
              </div>
            </div>
          </div>

          <div style={{ height: "50px" }}></div>

          <div className="page" id="english" style={{ paddingTop: "0px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Image style={{ paddingRight: "7px" }} width={50} height={50} src={Logo1} />
                  </div>
                </div>
                <div style={{ width: "250px" }}>
                  <center>
                    <h4 style={{ color: "#d00000", paddingTop: "5px", fontSize: 11 }}>
                      {municipalityEng} <br />
                      {fullAddressEngOrg}
                    </h4>
                    <div
                      style={{
                        flex: 1,
                        marginTop: "5px",
                        background: "#d00000",
                        color: "#FFF",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        fontSize: 13,
                        width: "170px"
                      }}
                    >
                      Senior Citizen Identity Card
                    </div>
                  </center>
                </div>
                <div style={{ flex: 1, paddingLeft: "15px", paddingTop: "3px" }}>
                  <Image
                    style={{}}
                    width={65}
                    height={72}
                    src={
                      "data:image/jpg;base64," +
                      idCardDetails.seniorCitizenIdCardPhotoInformation.fileData
                    }
                  />
                </div>
              </div>
              <div style={{ color: "black", width: "100%", float: "left", fontWeight: "bold", fontSize: 10, paddingLeft: 2 }}>
                <div style={{ width: "70%", float: "left" }}>
                  ID No. : {idCardDetails.idCardNo ? idCardDetails.idCardNo : idCardDetails.id}
                </div>
                <div style={{ width: "15%", float: "left" }}>
                  {/* ID Card Type : {idCardDetails.idCardType}{" "} */}
                </div>
                {isCopy ?
                  <div style={{ width: "15%", float: "right" }}>
                    <strong>Copy</strong>
                  </div>
                  :
                  <></>
                }
                <div style={{ width: "75%", float: "left" }}>
                  Name :{" "}
                  {idCardDetails.firstNameEng +
                    " " +
                    (idCardDetails.middleNameEng ? idCardDetails.middleNameEng : "") +
                    " " +
                    idCardDetails.lastNameEng}{" "}
                </div>
                <div style={{ width: "25%", float: "right" }}>
                  Sex : {idCardDetails.gender}{" "}
                </div>
                <div style={{ width: "75%", float: "left" }}>
                  Citizenship No. : {idCardDetails.citizenshipNo}{" "}
                </div>
                <div style={{ width: "25%", float: "right" }}>
                  Blood Group : {idCardDetails.bloodGroup}{" "}
                </div>
                {idCardDetails.dobNep ?
                  <div style={{ width: "100%", float: "left" }}>
                    {/* रोग: {idCardDetails.disease === "Yes" ? "छ" : "छैन"} */}
                    DOB : {idCardDetails.dobNep}
                  </div>
                  :
                  <div style={{ width: "100%", float: "right" }}>Age : {idCardDetails.age} </div>
                }
                {/* <div style={{ width: "75%", float: "left" }}>
                  Disease : {idCardDetails.disease === "Yes" ? "Yes" : "No"}
                </div> */}

              </div>
              <div style={{ color: "black", width: "100%", float: "left", fontWeight: "bold", fontSize: 10, paddingLeft: 2 }}>
                Address : {fullAddressEng} <br />
                Husband/Wife Name : {idCardDetails.fatherMotherGaurdianNameEng}
                {(idCardDetails.husbandWifefNameEng ? idCardDetails.husbandWifefNameEng : "") +
                  " " +
                  (idCardDetails.husbandWifemNameEng ? idCardDetails.husbandWifemNameEng : "") +
                  " " +
                  (idCardDetails.husbandWifelNameEng ? idCardDetails.husbandWifelNameEng : "")}
                <br />
                Care taker/Senior Citizen home : {idCardDetails.careTakerSenCitHomeEng}
                <br />
                <div style={{ width: "65%", float: "left" }}>
                  Contact Person : {idCardDetails.contactPersonfNameEng +
                    " " +
                    (idCardDetails.contactPersonmNameEng ? idCardDetails.contactPersonmNameEng : "") +
                    " " +
                    idCardDetails.contactPersonlNameEng}
                </div>
                <div style={{ width: "35%", float: "right" }}>
                  Phone No. : {idCardDetails.contactPersonNo}
                </div>
              </div>
            </div>

            <div style={{ color: "black", width: "100%", fontWeight: "bold", fontSize: 9, paddingLeft: 2 }}>
              <div style={{ width: "50%", float: "left" }}>
                {/* <strong style={{ fontSize: 11 }}>Types of Disability</strong> <br />
                On the basis of Nature: {idCardDetails.typeOfDisabilityByNature} <br />
                On the basis of Severity: {idCardDetails.typeOfDisabilityBySeverity} <br /> */}
                {idCardDetails.holderSignature != null ?
                  <div
                    style={{
                      flex: 1,
                      paddingLeft: "10px",
                      paddingTop: "15px",
                      paddingBottom: "22px",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <Image
                      style={{}}
                      width={90}
                      height={20}
                      src={
                        "data:image/jpg;base64," +
                        idCardDetails.holderSignature
                      }
                    />
                  </div>
                  :

                  <div
                    style={{
                      flex: 1,
                      paddingLeft: "10px",
                      paddingTop: "15px",
                      paddingBottom: "22px",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                  </div>
                }
                Signature of ID card holder
                <br />
                Issue Date : {idCardDetails.seniorCitizenApprovedDetails.dateEng}
              </div>
              <div
                style={{
                  width: "50%",
                  float: "right",
                  textAlign: "right",
                  paddingRight: 10,
                }}
              >
                <strong style={{ fontSize: 11 }}>Approved By</strong> <br />
                {idCardDetails.seniorCitizenApprovedDetails.approvalSignature != null ?
                  <div
                    style={{
                      flex: 1,
                      paddingLeft: "99px",
                      paddingTop: "2px",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <Image
                      style={{}}
                      width={90}
                      height={20}
                      src={
                        "data:image/jpg;base64," +
                        idCardDetails.seniorCitizenApprovedDetails.approvalSignature
                      }
                    />
                  </div>
                  :
                  <div
                    style={{
                      flex: 1,
                      paddingLeft: "99px",
                      paddingTop: "2px",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                  </div>
                }
                Signature <br />
                {idCardDetails.seniorCitizenApprovedDetails.nameEng} 
                {/* <br /> */}
                {/* {idCardDetails.seniorCitizenApprovedDetails.designationEng} */}
              </div>
            </div><br />
            <div style={{ color: "black", paddingTop: "48px", fontWeight: "bold", fontSize: 7, textAlign: "center" }}>
              If somebody finds this ID card, please deposit it in the nearby police station or local level office.
            </div>
          </div>



        </div>
      </Card>
      <div style={{ alignContent: "center" }}>
        <Button
          style={{ padding: "10px", margin: "10px" }}
          label="Print"
          className="p-button-raised p-button-rounded "
          onClick={() => setPrintModal(true)}
        />

        <Dialog header={t("isIdCardACopy")}
          footer={footer}
          visible={printModal}
          style={{ width: '50vw' }}
          onHide={() => onHide()}>
        </Dialog>
      </div>
    </div>
  );
}

export default SeniorCitizenIDCard;

function print(nepali, english) {
  console.log("nepali", nepali);
  var doc = new jsPDF("l", "mm", [107, 75]); //var doc = new jsPDF("l", "px", [ 390, 271]);

  const html2canvas1 = require('html2canvas');
  var i2 = document.createElement("img");
  html2canvas1(english, { scale: "5" }).then(canvas2 => { i2.src = canvas2.toDataURL(); });

  doc.html(document.createElement("div"),
    {
      callback: function (doc) {
        const html2canvas = require('html2canvas');
        html2canvas(nepali, { scale: "5" }).then(canvas1 => {
          doc.addImage(canvas1, "JPEG", 0, 0, 107, 75);
          doc.addPage();
          doc.addImage(i2.currentSrc, "JPEG", 0, 0, 107, 75, "alias1", 'SLOW');
          doc.output("dataurlnewwindow");
        });
      }
    })
}