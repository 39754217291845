import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";

import Logo1 from "../../scafolding/assets/images/govLogo.png";
import Stamp from "../../scafolding/assets/images/stamp.png";
import Photo from "../../scafolding/assets/images/photo.png";
import Signature from "../../scafolding/assets/images/signature.png";
import "./IDCard.css";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import AddressService from "../../security/api/services/AddressService";
import OrganizationService from "../../security/api/services/OrganizationService";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import {
  DISABILITY_BY_NATURE_NEPALI,
  DISABILITY_BY_SEVERITY_NEPALI,
  GENDER_NEPALI,
  ID_CATEGORY_NEPALI,
} from "../../utilities/constants/ITMISConstansts";

import QRCode from "qrcode.react";

function SmartSeniorCitizenIDCard(props) {
  const idCardDetails = props.location.state.data;
  const { t } = useTranslation();
  const [fullAddressEng, setFullAddressEng] = useState();
  const [fullAddressNep, setFullAddressNep] = useState();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [fullAddressEngOrg, setFullAddressEngOrg] = useState();
  const [fullAddressNepOrg, setFullAddressNepOrg] = useState();
  const [qrCodeDetailsEng, setQrCodeDetailsEng] = useState();
  const [qrCodeDetailsNep, setQrCodeDetailsNep] = useState();
  const [municipalityEng, setMunicipalityEng] = useState();
  const [municipalityNep, setMunicipalityNep] = useState();

  const [printModal, setPrintModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const footer = (
    <div>
      <Button label={t("ho")} icon="pi pi-check" onClick={async () => {
        await setIsCopy(true);
        await printFunction();
      }} />
      <Button label={t("haina")} icon="pi pi-times" onClick={async () => {
        await setIsCopy(false);
        await printFunction();
      }} />
    </div>
  );

  const printFunction = () => {
    //window.print()
    print(document.getElementById("nepali"), document.getElementById('english'));
  }

  const onHide = () => {
    setPrintModal(false);
  }

  const convertIdToNepali = (data) => {
    const idArray = data.split("_");
    let idNepali = "";

    if(idArray.length > 2) {
      idNepali =
        englishToNepaliNumber(idArray[0]) +
        "_" +
        englishToNepaliNumber(idArray[1]) +
        "_" +
        englishToNepaliNumber(idArray[2]) +
        "_" +
        ID_CATEGORY_NEPALI[idArray[3]] +
        "_" +
        englishToNepaliNumber(idArray[4]);
    }  else if(idArray.length = 2) {
      idNepali =
        englishToNepaliNumber(idArray[0]) +
        "_" +
        englishToNepaliNumber(idArray[1]);
    }
    return idNepali;
  };

  const convertDOBNepToNepali = (data) => {
    const dobNepArray = data.split("-");
    const dobNep =
      englishToNepaliNumber(dobNepArray[0]) +
      "-" +
      englishToNepaliNumber(dobNepArray[1]) +
      "-" +
      englishToNepaliNumber(dobNepArray[2]);
    return dobNep;
  };

  useEffect(() => {
    console.log("props.location.state.data", props.location.state.data);
    idCardDetails.idCardNepaliNo = convertIdToNepali(idCardDetails.id);
    AddressService.getFullAddressEng(
      props.location.state.data.seniorCitizenAddressDetails.province,
      props.location.state.data.seniorCitizenAddressDetails.district,
      props.location.state.data.seniorCitizenAddressDetails.municipality,
      props.location.state.data.seniorCitizenAddressDetails.wardNo
    ).then((response) => {
      let res = response.data.data;
      setFullAddressEng(res.replace(",sl", ", "));
    });
    AddressService.getFullAddressNep(
      props.location.state.data.seniorCitizenAddressDetails.province,
      props.location.state.data.seniorCitizenAddressDetails.district,
      props.location.state.data.seniorCitizenAddressDetails.municipality,
      props.location.state.data.seniorCitizenAddressDetails.wardNo
    ).then((response) => {
      let resN = response.data.data;
      setFullAddressNep(resN.replace(",sl", ", "));
    });
    // OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
    OrganizationService.getOrganizationById(props.location.state.data.organization).then((response) => {
      console.log(response.data.data);
      setOrganizationDetails(response.data.data);
      let wardVal = response.data.data.organizationAddress.ward ? response.data.data.organizationAddress.ward : 0;
      AddressService.getFullAddressEng(
        response.data.data.organizationAddress.province,
        response.data.data.organizationAddress.district,
        response.data.data.organizationAddress.municipality,
        wardVal
      ).then((response) => {
        console.log("english address>>", response.data.data);
        let engAdd = response.data.data;
        if (engAdd) {
          let eAdd = engAdd.split(",sl");
          console.log(eAdd);
          setMunicipalityEng(eAdd[0]);
          setFullAddressEngOrg(eAdd[1]);
        }
      });
      AddressService.getFullAddressNep(
        response.data.data.organizationAddress.province,
        response.data.data.organizationAddress.district,
        response.data.data.organizationAddress.municipality,
        wardVal
      ).then((response) => {
        console.log("nepali address>>", response.data.data);
        let nepAdd = response.data.data;
        if (nepAdd) {
          let nAdd = nepAdd.split(",sl");
          setMunicipalityNep(nAdd[0]);
          setFullAddressNepOrg(nAdd[1]);
        }
      });

      setQrCodeDetailsEng({
        name:
          idCardDetails.firstNameEng +
          " " +
          (idCardDetails.middleNameEng ? idCardDetails.middleNameEng : "") +
          " " +
          idCardDetails.lastNameEng,
        address: fullAddressEng
        // citizenshipNo: idCardDetails.citizenshipNo
      });
      setQrCodeDetailsNep({
        name:
          idCardDetails.firstNameNep +
          " " +
          (idCardDetails.middleNameNep ? idCardDetails.middleNameNep : "") +
          " " +
          idCardDetails.lastNameNep,
        //address: fullAddressNep,
        citizenshipNo: idCardDetails.citizenshipNo != null ? englishToNepaliNumber(idCardDetails.citizenshipNo) : ""
      });
    });
  }, [props]);

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Card
        style={{ display: "flex", justifyContent: "center", margin: "16px", borderRadius: "8px" }}
      >
        <div className="section-to-print">

          <div id="nepali"
            className="page"
            style={{
              background: "cardColor",
              margin: "0px auto",
              // width: "86mm",
              // height: "54mm",
              width: "75.5mm",
              height: "47.5mm",
              paddingTop: "0px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ alignItems: "center", marginTop: "5px" }}>
                  <div>
                    <Image width={30} height={30} src={Logo1} />
                  </div>
                </div>
                <div style={{ flex: 3, flexDirection: "column", paddingRight: 10 }}>
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      fontSize: 9,
                      color: "#d00000",
                    }}
                  >
                    <center>
                      <h4 style={{ color: "#d00000", paddingTop: "2px", fontWeight: "bold", fontSize: 8 }}>
                        {municipalityNep} <br />
                        {fullAddressNepOrg}
                      </h4>
                    </center>
                  </div>
                  <center>
                    <div
                      style={{
                        height: 11,
                        width: 100,
                        flex: 1,
                        background: "#d00000",
                        color: "#FFF",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: 8,
                        marginTop: "5px"
                      }}
                    >
                      ज्येष्ठ नागरिक परिचयपत्र
                    </div>
                  </center>
                </div>

                <div style={{ marginTop: "5px", alignItems: "right" }}>
                  <div>
                    <Image
                      width={30}
                      height={30}
                      src={
                        "data:image/jpg;base64," +
                        idCardDetails.seniorCitizenIdCardPhotoInformation.fileData
                      }
                    />
                  </div>
                </div>
              </div>

              <div style={{ color: "black", padding: 0, fontWeight: "bold", fontSize: 7 }}>
                <div>
                  <div style={{ float: "left", width: "85%" }}>परिचयपत्र नं. : {idCardDetails.idCardNo ? englishToNepaliNumber(idCardDetails.idCardNo) : idCardDetails.idCardNepaliNo}
                  </div>
                  {isCopy ?
                    <div style={{ width: "15%", float: "right" }}>
                      <strong>प्रतिलिपि</strong>
                    </div>
                    :
                    <></>
                  }
                </div>
                <div>
                  <div style={{ float: "left", width: "75%" }}>नाम, थर : {idCardDetails.firstNameNep +
                    " " +
                    (idCardDetails.middleNameNep ? idCardDetails.middleNameNep : "") +
                    " " +
                    idCardDetails.lastNameNep}
                  </div>
                  <div style={{ float: "left", width: "25%" }}>लिङ्ग : {GENDER_NEPALI[idCardDetails.gender]}</div>
                </div>
                <div>
                  <div style={{ float: "left", width: "70%" }}>नागरिकता प्रमाणपत्र नं. : {idCardDetails.citizenshipNo != null ? englishToNepaliNumber(idCardDetails.citizenshipNo) : ""}</div>
                  <div style={{ float: "left", width: "30%" }}>रक्त समूह : {idCardDetails.bloodGroup}</div>
                </div>
                <div>
                  <div style={{ float: "left", width: "70%" }}>पति/पत्नीको नाम : {idCardDetails.fatherMotherGaurdianNameEng}
                    {(idCardDetails.husbandWifefNameNep ? idCardDetails.husbandWifefNameNep : "")+
                      " " +
                      (idCardDetails.husbandWifemNameNep ? idCardDetails.husbandWifemNameNep : "") +
                      " " +
                      (idCardDetails.husbandWifelNameNep ? idCardDetails.husbandWifelNameNep : "")}
                  </div>
                  {idCardDetails.dobNep ?
                    <div style={{ width: "30%", float: "left" }}>
                      {/* रोग: {idCardDetails.disease === "Yes" ? "छ" : "छैन"} */}
                      जन्म मिति : {convertDOBNepToNepali(idCardDetails.dobNep)}
                    </div>
                    :
                    <div style={{ width: "30%", float: "left" }}>
                      उमेर : {idCardDetails.age != null ? englishToNepaliNumber(idCardDetails.age) : ""}
                    </div>
                  }
                </div>
                <div>
                  <div style={{ float: "left", width: "100%" }}>ठेगाना : {englishToNepaliNumber(fullAddressNep)}</div>
                  <div style={{ float: "left", width: "70%" }}>सम्पर्क व्यक्ति :&nbsp;
                    {idCardDetails.contactPersonfNameNep +
                      " " +
                      (idCardDetails.contactPersonmNameNep ? idCardDetails.contactPersonmNameNep : "") +
                      " " +
                      idCardDetails.contactPersonlNameNep}
                  </div>
                  <div style={{ width: "30%", float: "right" }}>
                    फोन नं :  {idCardDetails.contactPersonNo != null ? englishToNepaliNumber(idCardDetails.contactPersonNo) : ""}
                  </div>
                </div>
              </div>
              <div style={{ color: "black", display: "flex", fontWeight: "bold", fontSize: 7, flexDirection: "row" }}>
                <div style={{ flex: 3, display: "flex", flexDirection: "column", paddingRight: 2 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 7,
                      paddingTop: 20,
                    }}
                  >

                    {idCardDetails.holderSignature != null ?
                      <div
                        style={{
                          flex: 1,
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <Image
                          style={{}}
                          width={75}
                          height={20}
                          src={
                            "data:image/jpg;base64," +
                            idCardDetails.holderSignature
                          }
                        />
                      </div>
                      :
                      <div
                        style={{
                          flex: 1,
                          paddingBottom: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                      </div>
                    }

                    <div style={{ flex: 1 }}>हस्ताक्षर </div>
                    <br />
                    {/* जारी मिति: {convertDOBNepToNepali(idCardDetails.seniorCitizenApprovedDetails.dateNep)} */}
                  </div>
                </div>
                <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <QRCode id="qr" style={{ fontWeight: "bold" }} size={60} value={JSON.stringify(qrCodeDetailsNep)} />
                </div>
                <div
                  style={{
                    flex: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 7,
                    }}
                  >
                    <div style={{ flex: 4 }}>
                      <strong>स्वीकृत गर्ने</strong>
                    </div>


                    {/* <div style={{ flex: 4, paddingTop: 0 }}>..........................</div> */}
                    {idCardDetails.seniorCitizenApprovedDetails.approvalSignature != null ?
                      <div
                        style={{
                          paddingLeft: "49px",
                          paddingBottom: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <Image
                          style={{}}
                          width={75}
                          height={20}
                          src={
                            "data:image/jpg;base64," +
                            idCardDetails.seniorCitizenApprovedDetails.approvalSignature
                          }
                        />
                      </div>
                      :
                      <div
                        style={{
                          flex: 1,
                          paddingLeft: "35px",
                          paddingBottom: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                      </div>
                    }
                    <div style={{}}>{idCardDetails.seniorCitizenApprovedDetails.nameNep}</div>
                    <div style={{ flex: 4 }}>
                      {idCardDetails.seniorCitizenApprovedDetails.designationNep}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div style={{ color: "black", paddingTop: "5px", fontWeight: "bold", fontSize: 5, textAlign: "center" }}>
              यो परिचयपत्र कसैले पाएमा नजिकको प्रहरी कार्यालय वा स्थानीय निकायमा बुझाइदिनुहोला |
            </div>
          </div>

          <div style={{ height: "50px" }}></div>

          <div id="english"
            className="page"
            style={{
              background: "cardColor",
              margin: "0px auto",
              // width: "86mm",
              // height: "54mm",
              width: "75.5mm",
              height: "47.5mm",
              paddingTop: "0px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ alignItems: "center", marginTop: "5px" }}>
                  <div>
                    <Image width={30} height={30} src={Logo1} />
                  </div>
                </div>
                <div style={{ flex: 3, flexDirection: "column", paddingRight: 10 }}>
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      fontSize: 9,
                      color: "#d00000",
                    }}
                  >
                    <center>
                      <h4 style={{ color: "#d00000", paddingTop: "2px", fontWeight: "bold", fontSize: 8 }}>
                        {municipalityEng} <br />
                        {fullAddressEngOrg}
                      </h4>
                    </center>
                  </div>
                  <center>
                    <div
                      style={{
                        height: 11,
                        width: 135,
                        background: "#d00000",
                        color: "#FFF",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: 8,
                      }}
                    >
                      Senior Citizen Identity Card
                    </div>
                  </center>
                </div>

                <div style={{ marginTop: "5px", alignItems: "right" }}>
                  <div>
                    <Image
                      width={30}
                      height={30}
                      src={
                        "data:image/jpg;base64," +
                        idCardDetails.seniorCitizenIdCardPhotoInformation.fileData
                      }
                    />
                  </div>
                </div>
              </div>

              <div style={{ color: "black", padding: 0, fontWeight: "bold", fontSize: 7 }}>
                <div>
                  <div style={{ float: "left", width: "85%" }}>ID No. : {idCardDetails.idCardNo ? idCardDetails.idCardNo : idCardDetails.id}
                  </div>
                  {/* {isCopy ?
                    <div style={{ width: "15%", float: "right" }}>
                      <strong>प्रतिलिपि</strong>
                    </div>
                    :
                    <></>
                  } */}
                </div>
                <div>
                  <div style={{ float: "left", width: "70%" }}>Name : {idCardDetails.firstNameEng +
                    " " +
                    (idCardDetails.middleNameEng ? idCardDetails.middleNameEng : "") +
                    " " +
                    idCardDetails.lastNameEng}
                  </div>
                  <div style={{ float: "left", width: "30%" }}>Sex : {idCardDetails.gender}</div>
                </div>
                <div>
                  <div style={{ float: "left", width: "65%" }}>CitizenShip No. : {idCardDetails.citizenshipNo}</div>
                  <div style={{ float: "left", width: "35%" }}>Blood Group : {idCardDetails.bloodGroup}</div>
                </div>
                <div style={{ float: "left", width: "65%" }}>Husband/Wife Name : 
                {(idCardDetails.husbandWifefNameEng ? idCardDetails.husbandWifefNameEng : "") +
                  " " +
                  (idCardDetails.husbandWifemNameEng ? idCardDetails.husbandWifemNameEng : "") +
                  " " +
                  (idCardDetails.husbandWifelNameEng ? idCardDetails.husbandWifelNameEng : "")}
                </div>
                {idCardDetails.dobNep ?
                  <div style={{ width: "35%", float: "left" }}>
                    {/* रोग: {idCardDetails.disease === "Yes" ? "छ" : "छैन"} */}
                    DOB : {idCardDetails.dobNep}
                  </div>
                  :
                  <div style={{ width: "35%", float: "right" }}>Age : {idCardDetails.age} </div>
                }
                <div>
                  <div style={{ float: "left", width: "100%" }}>Address : {fullAddressEng}</div>
                </div>
                <div>
                  <div style={{ float: "left", width: "65%" }}>Contact Person :&nbsp;
                    {idCardDetails.contactPersonfNameEng +
                      " " +
                      (idCardDetails.contactPersonmNameEng ? idCardDetails.contactPersonmNameEng : "") +
                      " " +
                      idCardDetails.contactPersonlNameEng}
                  </div>
                  <div style={{ width: "35%", float: "right" }}>
                    Phone No. : {idCardDetails.contactPersonNo}
                  </div>
                </div>
              </div>
              <div style={{ color: "black", display: "flex", flexDirection: "row", fontWeight: "bold", fontSize: 7, }}>
                <div style={{ flex: 2, display: "flex", flexDirection: "column", paddingRight: 2 }}>
                  <div
                    style={{
                      // flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 7,
                      // paddingTop: 20,
                    }}
                  >
                    {idCardDetails.holderSignature != null ?
                      <div
                        style={{
                          // flex: 1,                    
                          width: "20px",
                          paddingBottom: "20px",
                          height: "20px",
                        }}
                      >
                        <Image
                          style={{}}
                          width={75}
                          height={20}
                          src={
                            "data:image/jpg;base64," +
                            idCardDetails.holderSignature
                          }
                        />
                      </div>
                      :
                      <div
                        style={{
                          // flex: 1,    
                          paddingBottom: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                      </div>
                    }
                    <div>Signature</div>
                    Issue Date: {idCardDetails.seniorCitizenApprovedDetails.dateEng}
                  </div>
                </div>
                <div
                  style={{
                    flex: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 7
                    }}
                  >
                    <div style={{ flex: 4 }}>
                      <strong>Approved By</strong>
                    </div>
                    {idCardDetails.seniorCitizenApprovedDetails.approvalSignature != null ?
                      <div
                        style={{
                          paddingLeft: "65px",
                          paddingBottom: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <Image
                          style={{}}
                          width={75}
                          height={20}
                          src={
                            "data:image/jpg;base64," +
                            idCardDetails.seniorCitizenApprovedDetails.approvalSignature
                          }
                        />
                      </div>
                      :
                      <div
                        style={{
                          paddingLeft: "145px",
                          paddingBottom: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                      </div>
                    }


                    {/* <div style={{ flex: 4, paddingTop: 0 }}>..........................</div> */}
                    <div style={{ flex: 4 }}>{idCardDetails.seniorCitizenApprovedDetails.nameEng}</div>
                    <div style={{ flex: 4 }}>
                      {idCardDetails.seniorCitizenApprovedDetails.designationEng}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ color: "black", paddingTop: "7px", fontWeight: "bold", fontSize: 5, textAlign: "center" }}>
                If somebody finds this ID card, please deposit it in the nearby police station or local level office.
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div style={{ alignContent: "center" }}>
        <Button
          style={{ padding: "10px", margin: "10px" }}
          label="Print"
          className="p-button-raised p-button-rounded "
          onClick={() => setPrintModal(true)}
        />
        <Dialog header={t("isIdCardACopy")}
          footer={footer}
          visible={printModal}
          style={{ width: '50vw' }}
          onHide={() => onHide()}>
        </Dialog>
      </div>
      <div style={{ flex: 1, display: "none", justifyContent: "center", alignItems: "center" }} id="f">
        <QRCode style={{ fontWeight: "bold" }} value={JSON.stringify(qrCodeDetailsNep)} />
      </div>
    </div>
  );
}

export default SmartSeniorCitizenIDCard;

function print(nepali, english) {
  var doc = new jsPDF("l", "mm", [85, 53]);

  //alert(nepali.childNodes[0].childNodes[2].childNodes[1].innerHTML);
  var i1 = document.createElement("img");
  i1.src = document.getElementById("f").childNodes[0].toDataURL();
  i1.style.width = "55px";
  nepali.childNodes[0].childNodes[2].childNodes[1].innerHTML = "";//document.getElementById("f").innerHTML;
  nepali.childNodes[0].childNodes[2].childNodes[1].style.marginLeft = "35px";
  nepali.childNodes[0].childNodes[2].childNodes[1].appendChild(i1);

  const html2canvas1 = require('html2canvas');
  var i2 = document.createElement("img");
  html2canvas1(english, { scale: "5" }).then(canvas2 => { i2.src = canvas2.toDataURL(); });

  doc.html(document.createElement("div"),
    {
      callback: function (doc) {
        const html2canvas = require('html2canvas');
        html2canvas(nepali, { scale: "5" }).then(canvas1 => {
          doc.addImage(canvas1, "JPEG", 0, 0, 85, 53, "alias2", 'SLOW');
          doc.addPage();
          doc.addImage(i2.currentSrc, "JPEG", 0, 0, 85, 53, "alias1", 'SLOW');
          doc.output("dataurlnewwindow");
        });
      }
    })
}
